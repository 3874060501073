import React from 'react'
import Layout from '../components/Layout'
import { graphql } from 'gatsby'
import { getImage } from 'gatsby-plugin-image'
import { seo } from '../content/services'
import Seo from '../components/Seo'
import Intro from '../components/Intro'
import TemplateBody from '../components/TemplateBody'
import { useLangContext } from '../context/lang.context'
import { sCenteredSpaced } from '../style'
import Button from '../components/Button'
import { useFormContext } from '../context/form.context'

export default function ServiceTemplate({ data }) {
  const { lang } = useLangContext()
  const { setFormOpen } = useFormContext()

  let service = data.services.nodes.find((node) => node.lang.slug === lang)
  if (!service) service = data.services.nodes[0]

  const {
    name,
    title,
    slug,
    keywords,
    image,
    body,
    description,
    createdAt,
    updatedAt,
    imagecontent,
  } = service

  const imageData = getImage(image)

  const buttonIntro = {
    text: {
      en: 'Read more',
      es: 'Leer más',
      ro: 'Citiți mai mult',
    },
    action: 'SCROLL',
  }

  const buttonQuote = {
    text: {
      en: 'Order transport',
      es: 'Transporte de pedidos',
      ro: 'Comanda transport',
    },
    action: () => setFormOpen(true),
    glow: true,
  }

  const buttonParent = {
    text: {
      en: 'Explore other services',
      es: 'Explora otros servicios',
      ro: 'Explorați alte servicii',
    },
    link: '/services/',
    dimmed: true,
  }

  return (
    <Layout>
      <Seo
        title={title}
        desc={description}
        imageUrl={image.file.url}
        url={slug}
        keywords={[...keywords, 'quote']}
        createdAt={createdAt}
        updatedAt={updatedAt}
      />
      <Intro
        h={1}
        title={name}
        desc={seo.desc[lang]}
        image={imageData}
        button={buttonIntro}
      />
      <TemplateBody
        title={description}
        body={body.childMarkdownRemark.html.replaceAll('h1>', 'h2>')}
        image={imagecontent ? getImage(imagecontent) : imageData}
        imageCredits={image?.description}
        buttons={[buttonParent, buttonQuote]}
      />
      <div css={sCenteredSpaced}>
        <Button reactive link={buttonBack.link}>
          {buttonBack.text[lang]}
        </Button>
      </div>
    </Layout>
  )
}

const buttonBack = {
  text: {
    en: 'Back to transport services',
    es: 'Volver a servicios de transporte',
    ro: 'Înapoi la serviciile de transport',
  },
  link: '/services/',
}

export const query = graphql`
  query ($slug: String!) {
    services: allContentfulTransportService(filter: { slug: { eq: $slug } }) {
      nodes {
        lang {
          slug
        }
        id
        createdAt
        updatedAt
        slug
        name
        title
        description
        keywords
        body {
          childMarkdownRemark {
            html
          }
        }
        image {
          description
          gatsbyImageData(
            quality: 100
            placeholder: DOMINANT_COLOR
            formats: [JPG]
          )
          file {
            url
            fileName
          }
        }
        imagecontent {
          description
          gatsbyImageData(
            quality: 100
            placeholder: DOMINANT_COLOR
            formats: [JPG]
            layout: FIXED
          )
        }
      }
    }
  }
`
