export const seo = {
  url: 'services',
  title: {
    en: 'Types and Kinds of Transport | Transportation services',
    es: 'Tipos y Clases de Transporte | Servicios de transporte',
    ro: 'Tipuri și tipuri de transport | Servicii de transport',
  },
  desc: {
    en: 'Find out more about the modes of transport. Check out the most popular transport services and choose the one that suits your business.',
    es: 'Obtenga más información sobre los modos de transporte. Consulta los servicios de transporte más populares y elige el que se adapte a tu negocio.',
    ro: 'Aflați mai multe despre modurile de transport. Consultați cele mai populare servicii de transport și alegeți-l pe cel care se potrivește afacerii dvs.',
  },
  keywords: [
    'omida',
    'logistics',
    'transport',
    'transport types',
    'transport services',
    'ftl',
    'ltl',
    'transport quote',
  ],
}

export const intro = {
  title: {
    en: 'Transport Services',
    es: 'Servicios de transporte',
    ro: 'Servicii de transport',
  },
  desc: {
    en: 'Find out more about the modes of transport. Check out the most popular transport services and choose the one that suits your business.',
    es: 'Obtenga más información sobre los modos de transporte. Consulta los servicios de transporte más populares y elige el que se adapte a tu negocio.',
    ro: 'Aflați mai multe despre modurile de transport. Consultați cele mai populare servicii de transport și alegeți-l pe cel care se potrivește afacerii dvs.',
  },
  button: {
    text: {
      en: 'Read more',
      es: 'Leer más',
      ro: 'Citiți mai mult',
    },
    action: 'SCROLL',
  },
}

export const main = {
  title: {
    en: 'Transport types',
    es: 'Tipos de transporte',
    ro: 'Tipuri de transport',
  },
  texts: [
    {
      en: '<span>Omida Logistics is a transport company with a <strong> wide range of products</strong> and transport services, <strong>dedicated to individual branches</strong> of the economy and <strong>specialized units</strong>. We offer various forms of transport, and details of each form are presented in the products detailed below.</span>',
      es: '<span> Omida Logistics es una empresa de transporte con <strong> una amplia gama de productos </strong> y servicios de transporte, <strong> dedicada </strong> a ramas individuales de la economía y <strong> unidades especializadas </strong> . Ofrecemos varias formas de transporte, y los detalles de cada forma se presentan en los productos que se detallan a continuación.</span>',
      ro: '<span>Omida Logistics este o companie de transport cu o <strong>gamă largă de produse</strong> și servicii de transport, <strong>dedicată ramurilor individuale</strong> ale economiei și <strong>unităților specializate</strong>. Oferim diverse forme de transport, iar detaliile fiecărui formular sunt prezentate în produsele detaliate mai jos.</span>',
    },
  ],
}

export const buttonBack = {
  text: {
    en: 'Back to transport',
    es: 'Volver a transporte',
    ro: 'Înapoi la transport',
  },
  link: '/transport/',
}
